import React from 'react'
import '../styles/globals.css'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import * as FullStory from '@fullstory/browser'
import { ThemeProvider, createTheme } from '@material-ui/core'
import Head from 'next/head'

import { NotificationsProvider } from '@/components/context/NotificationsContext'
import { SiteAssetsProvider } from '@/components/context/SiteAssetsContext'
import Analytics from '@/components/Analytics'
import AuthModal from '@/components/auth/AuthModal'
import Footer from '@/components/pages/Footer'
import Header from '@/components/Header/Header'
import OptimizelyProvider from '@/components/OptimizelyProvider'
import SEO from '@/components/pages/SEO'
import Zendesk from '@/components/Zendesk'
import { AuthProvider } from '@/components/context/AuthContext'
import Identify from '@/components/auth/Identify'
import BellNotificationSignal from '@/components/notifications/BellNotifications/BellNotificationSignal'

import colors from '@/config/colors'
import app from '../package.json'

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG,
  appVersion: app.version,
  enabledReleaseStages: ['production', 'staging'],
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_PUBLIC_ENV_NAME,
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

const theme = createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.secondary },
  },
})

if (typeof window !== 'undefined') {
  FullStory.init({
    orgId: process.env.NEXT_PUBLIC_FULLSTORY,
    devMode: process.env.NEXT_PUBLIC_ENV_NAME !== 'production',
  })
}

const App = ({ Component, pageProps, router: serverRouter }) => {
  return (
    <ErrorBoundary>
      <NotificationsProvider>
        <OptimizelyProvider>
          <AuthProvider>
            <BellNotificationSignal />
            <SiteAssetsProvider assets={pageProps?.siteAssets}>
              <Head>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1"
                />
              </Head>
              <SEO {...pageProps?.seo} url={serverRouter.asPath} />
              <Analytics {...pageProps} />
              <Zendesk />
              <Identify />

              <Header navParent={pageProps?.navParent} />

              <ThemeProvider theme={theme}>
                <div className="flex-1 font-whitney">
                  <AuthModal />

                  <Component {...pageProps} key={serverRouter.route} />
                </div>
                <Footer />
              </ThemeProvider>
            </SiteAssetsProvider>
          </AuthProvider>
        </OptimizelyProvider>
      </NotificationsProvider>
    </ErrorBoundary>
  )
}

export default App
